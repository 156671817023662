<template>
  <v-main class="point text-center pa-4">
    <div v-if="!authorized" class="text-center caption pt-10">
      <!-- アクセストークン確認中 -->
    </div>
    <div v-else>
      <!-- チャート -->
      <div class="text-center mb-5">
        <!-- POS連携用バーコード表示 -->
        <div>
            <div class="barcode">
                <svg ref="barcode" v-show="isNotErr"></svg>
                <div v-if="!isNotErr"><b>端末の通信状態が不安定です。<br> 電波の良いところでお試しください。</b></div>
                <div class="div-btn">
                    <span v-if="isNotErr">{{ formatTime }}</span>
                    <button class="button-re" type="button" @click="reloadBarcode()" :class="isLoad ? 'active-load' :'' "><img src="/bar-load.png"></button>
                </div>
                
            </div>
        </div>
        <!-- ポイント数用サークル表示 -->
        <v-progress-circular
          :rotate="-90"
          :width="30"
          :value="value"
          :color="maincolor"
          :size="240">
          <div class="text-center" align="center" style="color:#333; font-size:0.6125rem;">
            <div class="px-10">
              <!-- ランク名 -->
              <span v-if="rank_str" style="display:block; font-weight:bold; letter-spacing:0.1rem;">{{ rank_str }}ランク</span>
              <span class="bigpoint"><AnimatedNumber :value="num"></AnimatedNumber></span>
              <span class="pl-1 smallpoint">P</span><br />
              <div class="text-center pb-3">
                <v-btn rounded depressed
                  class="maincolor-bg mb-0 p-0"
                  style="font-size:0.6125rem; height:1.25rem; letter-spacing:0.05rem;"
                  @click="showPointHistoryDialog()">ポイント履歴
                </v-btn>
              </div>
              <div class="px-0 py-0">
                <p v-if="point" class="my-0 py-0">
                  有効期限 {{point.expiration_date}}
                </p>
              </div>
            </div>
          </div>
        </v-progress-circular>
      </div>

      <v-card v-if="point" class="pt-2 pb-0 px-3 mb-6">
        <v-row class="ma-0">
          <!-- ポイントランク表示 -->
<!--          <v-col cols="7" class="px-2 py-0">-->
          <v-col cols="9" class="px-2 py-0">
            <v-row v-if="point.rankmax_flg == 0" class="ma-0 pb-1">
              <v-col class="pa-0 text-left">
                <span style="font-size:0.5125rem;">次のランクまであと</span>
              </v-col>
              <v-col class="pa-0 text-right">
                <span v-if="point" class="maincolor bigpoint3">{{ point.rank_remaining.toLocaleString() }}</span>
                <span class="maincolor font-weight-bold" style="font-size:0.6125rem;">P</span>
              </v-col>
            </v-row>
            <v-row v-if="point.rankmax_flg == 1" class="ma-0 pb-1">
              <v-col class="pa-0 text-left">
                <span style="font-size:0.5125rem;">最上ランクです。</span>
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col class="pa-0">
                <v-btn rounded depressed class="maincolor-border-btn fontsize9 m-1 justify-content-center" @click="showPointAboutDialog()">ランク・ポイントについて</v-btn>
              </v-col>
            </v-row>
          </v-col>
          <!-- ポイントQRリーダー表示 ※コメントアウト。復活させるときは、ポイントランク表示をcols=7で。 -->
<!--          <v-col cols="2" class="py-0 px-2 mt-1 text-center col col-2" @click.stop="showPointQRReader()">-->
<!--            <img src="@/assets/point/scan.svg" width="55">-->
<!--          </v-col>-->
          <!-- スタンプQR表示 -->
          <v-col cols="3" class="py-0 pl-5 text-center" @click="showStampQRDialog()">
            <qrcode :qrcodeurl="qrcodeurl" :options="{ width: 64, margin: 3 }"></qrcode>
          </v-col>
        </v-row>
      </v-card>

      <v-card v-for="(stampdata, index) in stamp.stamp_data" :key="index" class="mb-1">
        <h3 class="bg_stamp">
          来店スタンプ
          <span style="display: block; font-size: 0.6125rem;">{{ stampdata.complete_number }}個あつめるとやまや商品プレゼント！</span>
        </h3>
        <div class="text-center mb-0 px-0 pb-0">
          <v-row no-gutters class="px-4 justify-space-around align-start">
            <div class="mb-1 pa-1" v-for="stampnum in stampdata.stamps" :key="stampnum.id">
              <v-avatar :color="$root.maincolorop" size="48">
                <img v-if="stampnum.image_path" :src="stampnum.image_path">
                <img v-else src="@/assets/i_stamp_90.png">
              </v-avatar>
              <p class="fontsize9 maincolor text-center">{{ stampnum.get_date }}</p>
            </div>

            <div class="mb-1 pa-1" v-for="val in empty_stamps[index]" :key="val.id">
              <v-avatar color="#F4F4F4" size="48">
                <span class="title" style="color:#DEDEDF;">{{ val }}</span>
              </v-avatar>
              <p class="fontsize9 maincolor text-center"></p>
            </div>
          </v-row>
        </div>
        <div class="text-center mt-2 pb-5">
          <a class="fontsize11 pa-2" style="color:#333; text-decoration:underline;" @click="showStampAboutDialog()">※対象店舗はこちら</a>
        </div>
        <div class="pt-2">
          <v-btn
            rounded
            depressed
            class="maincolor-bg btn_small mb-0 pb-0 fontsize13 font-weight-bold"
            style="width:12.5rem;"
            @click.stop="showStampActivity(stamp.id, stamp.auth_type, stamp.shop_id)"
          >
            来店スタンプGET
          </v-btn>
        </div>
        <div class="py-4 font-weight-bold">
          <v-btn
            v-if="stamp.is_complete_ticket == 1"
            rounded
            depressed
            class="btn_small mb-0 pb-0 fontsize11 maincolor-border-btn"
            style="width:12.5rem;"
            @click.stop="routerBindTo({ name: 'stamp_bonuslist', query: { stamp_id: stamp.id, shop_id: stamp.shop_id}})"
          >
            獲得チケットを確認する
          </v-btn>
          <v-btn
            v-else
            rounded
            depressed
            disabled
            class="btn_small mb-0 pb-0 fontsize11 maincolor-border-btn-disabled"
            style="width:12.5rem; background-color:#F4F4F4 !important;"
            @click.stop="routerBindTo({ name: 'stamp_bonuslist', query: { stamp_id: stamp.id, shop_id: stamp.shop_id}})"
          >
            獲得チケットを確認する
          </v-btn>
        </div>
      </v-card>

      <v-row class="mt-8 mx-0 mb-2 px-3 text-left" style="font-size:0.95rem; line-height:1.5;">
        <v-col class="pa-0 font-weight-bold">来店スタンプについて</v-col>
      </v-row>
      <v-row class="ma-0 px-3 text-left" style="font-size:0.675rem; ">
        <v-col class="pa-0">
          <p>対象店舗への来店ご利用時に貯まるスタンプです。</p>
          <p>10個貯めるとコンプリートチケットが発行され、やまやの商品がもらえます。</p>
        </v-col>
      </v-row>
      <v-row class="mt-8 mx-0 mb-2 px-3 text-left" style="font-size:0.95rem; line-height:1.5;">
        <v-col class="pa-0 font-weight-bold">有効期限</v-col>
      </v-row>
      <v-row class="ma-0 mb-10 px-3 text-left" style="font-size:0.675rem; ">
        <v-col class="pa-0">
          <span v-if="stamp.expiration_date">{{ stamp.expiration_date }}</span>
          <span v-else>なし<br>※コンプリートチケットの有効期限は発行日から90日間です。</span>
        </v-col>
      </v-row>
    </div>
    <v-overlay :value="loading">
      <v-progress-circular :size="80" :width="2" class="maincolor" indeterminate></v-progress-circular>
    </v-overlay>

    <!-- ハローダイアログ -->
    <v-dialog v-model="helloDialog" persistent>
      <v-card class="p-3">
        <div class="maincolor-bg text-center">
          <img class="mt-8 mb-3" src="@/assets/point/wpointchance_text.png" width="70%">
          <img class="mb-8" src="@/assets/point/yamaya_wpoint.png" width="35%">
        </div>
        <div class="px-3 text-center">
          <h5 class="mt-2 mb-2 font-weight-bold ribbon1">食べて＆買って＋ポイント</h5>
          <h5 class="font-weight-bold ribbon1">通って＆買って＋ポイント</h5>

          <p class="caption my-5">お得についての内容を・・・・・ </p>

          <v-checkbox v-model="neverShowsHelloPointDialog" class="ml-10 pl-10">
            <template v-slot:label>
              <span style="font-size: 11px">今後は表示しない</span>
            </template>
          </v-checkbox>

          <div class="">
            <v-btn rounded depressed class="caption font-weight-black mb-10 w80" style="background-color:#EFEFEF;" @click.native="closeHelloDialog()">閉じる</v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <!-- ポイント付与メッセージダイアログ -->
    <v-dialog v-model="pointQrResultMessageDialog" persistent>
      <v-card class="p-3">
        <div class="maincolor-bg text-left">
          <img class="pl-2 pt-2" src="@/assets/point/message_header.png" width="15%">
          <div class="maincolor-bg text-center">
            <p class="pt-5 pb-10 px-3" style="font-size: 1.0rem">{{ point_qr_result_message }} </p>
          </div>
        </div>
        <div class="px-3 py-5 text-center">
          <v-btn rounded class="maincolor-bg default_button btn_small" @click.native="closePointQrResultMessageDialog()">閉じる</v-btn>
        </div>
      </v-card>
    </v-dialog>

    <!-- ポイント履歴ダイアログ -->
    <PointHistoryDialog :point_history="point_history" :init="init" ref="pointHistoryDialog"></PointHistoryDialog>

    <!-- ポイントランクについてダイアログ -->
    <PointAboutDialog :point="point" :init="init" ref="pointAboutDialog"></PointAboutDialog>

    <!-- ポイントランクアップダイアログ -->
    <PointRankUpDialog :point_rankup="point_rankup" :init="init" ref="pointRankUpDialog"></PointRankUpDialog>

    <!-- QR codeについてダイアログ -->
    <QRDialog :qrcodeurl="qrcodeurl" ref="qrDialog"></QRDialog>

    <!-- スタンプについてダイアログ -->
    <StampAboutDialog :stamp="stamp" ref="stampAboutDialog"></StampAboutDialog>
  </v-main>
</template>

<script>
import StampAboutDialog from "@/views/wpoint/StampAboutDialog.vue";
import PointHistoryDialog from "@/views/wpoint/PointHistoryDialog.vue";
import PointAboutDialog from "@/views/wpoint/PointAboutDialog.vue";
import PointRankUpDialog from "@/views/wpoint/PointRankUpDialog.vue";
import AnimatedNumber from "@/components/AnimatedNumber.vue";
import QRDialog from '@/views/wpoint/QRDialog.vue';
import JsBarcode from 'jsbarcode';
import moment from 'moment-timezone';

export default {
  components: {
    StampAboutDialog,
    PointHistoryDialog,
    PointAboutDialog,
    PointRankUpDialog,
    AnimatedNumber,
    QRDialog,
  },
  data: () => ({
    init: [],
    authorized : false,
    maincolor: null,

    helloDialog : false,
    neverShowsHelloPointDialog : false,

    pointQrResultMessageDialog : false,

    value: 0,
    num: 0,

    point: null,
    point_history: null,
    point_rankup: null,
    qrcodeurl: null,
    rank_str: null,
    point_qr_result_message : null,

    stamp: [],
    empty_stamps: [],
    loading: false,
    
    intervalId: null,
    timestamp: null,
    timeLeft: 0,
    barcode: null,
    res: null,
    isLoad: false,
    timezone: '',
    local:'',
    isNotErr: true,
    timeout: 10000,
    
  }),
  async created () {
    this.loading = true;
    this.init = this.storageGet("*")
    this.maincolor = this.init.color_code
    this.qrcodeurl = process.env.VUE_APP_ADMIN_URL + "/points/" + this.init.user_id + "/edit?shopId=" + this.init.sid + "&shopName=" + this.init.shop_name + "&nowDate=" + Date.parse(new Date());

    try {
      // アクセストークンチェックAPI
      const token_check_req = {}
      await this.apiCallCustomerPF('/access_token/check/' + this.init.device_uid, token_check_req)
      this.authorized = true;
    } catch(e) {
      this.authorized = false
      this.loading = false;
      console.log(e)
      this.requestToNativeToShowLogin()
      return
    }

    try{
    
      // ポイントQR付与メッセージ取得API用パラメータ生成
      let user_message_req = {'params': {
        'message_type' : 'point_qr_result'
      }}

      // スタンプ関連取得API用パラメータ生成
      let stamplist_req = {'params': {
        'shop_id': this.init.sid,
        'page': 1,
      }}

      // ポイント情報取得API用パラメータ生成
      let point_req = {params: {
        'sid': this.init.sid,
        'key': this.init.key,
        'device_id': this.init.device_id,
        'device_uid': this.init.device_uid,
        'os': this.init.os,
        'app_version': this.init.app_version,
      }}

      // API一括送信（ポイントQR付与メッセージ、スタンプリスト、ポイント、ポイント履歴）
      let [
          user_message_res,
          stamplist_res,
          point_res,
          point_history_res,
        ] = await Promise.all([
          this.apiCall('/user/message', user_message_req),
          this.apiCall('/stamp/top-list', stamplist_req),
          this.apiCall('/point', point_req),
          this.apiCall('/point/history', point_req),
        ]);

      /*
       * ポイントQR付与メッセージがあれば表示
       * ※メッセージをクエリパラメータで渡すと、Nativeの戻るボタンで行き来する度に表示されてしまうのでやや遠まわしだがDB経由で渡す。
       */
      if (user_message_res.user_messages.length) {
        // // 2つ以上取れることはないはずだが、念のため最後
        this.showPointQrResultMessageDialog(user_message_res.user_messages[user_message_res.user_messages.length - 1]);
      }

      /*
       * ポイント関連取得結果を設定
       */
      if (!point_res) return false;
      this.$set(this, 'point', point_res);

      //ランクのテキストを設定
      if (this.point.rank != null) {
        this.rank_str = this.point.rank_detail[this.point.rank - 1].rank_str;
      }

      let stamp_req = null;
      let stamp_res = null;
      let point_rankup_req = null;
      let point_rankup_res = null;

      //スタンプ詳細取得API用パラメータ生成
      if (stamplist_res.data && stamplist_res.data.length != 0) {
        stamp_req = {'params': {
          'stamp_id': stamplist_res.data[0].id, // 1件目を取得
          'shop_id': this.init.shop_id,
        }}
      }

      //ポイントランク情報API用パラメータ生成
      if(this.point.rank != null){ //ランク機能を利用しているかどうか判別
        //最大ランクに到達しているかどうか
        if(this.point.rank == this.point.rank_detail.length){
          this.point['rankmax_flg'] = 1
        }else{
          this.point['rankmax_flg'] = 0
        }

        //ポイント情報取得API
        point_rankup_req = {
          'sid': this.init.sid,
          'key': this.init.key,
          'device_id': this.init.device_id,
          'device_uid': this.init.device_uid,
          'os': this.init.os,
          'app_version': this.init.app_version,
        }
      }

      //API送信
      if ( stamplist_res.data && stamplist_res.data.length != 0 && this.point.rank != null ) {
        // API一括送信（スタンプ詳細、ポイントランク情報）
        [
          point_rankup_res,
          stamp_res,
        ] = await Promise.all([
            this.apiCall('/point/get-rank', point_rankup_req, 'post'),
            this.apiCall('/stamp/detail', stamp_req, 'get'),
          ]);
      } else if (stamplist_res.data && stamplist_res.data.length != 0) {
        // スタンプ詳細API
        stamp_res = await this.apiCall('/stamp/detail', stamp_req, 'get');
      } else if (this.point.rank != null) {
        // ポイントランク情報API
        point_rankup_res = await this.apiCall('/point/get-rank', point_rankup_req, 'post');
      }

      // スタンプ詳細取得結果を設定
      if (stamplist_res.data && stamplist_res.data.length != 0) {
        if (!stamp_res) return false;
        this.$set(this, 'stamp', stamp_res.data);

        let start = 1;
        let tmpArray = null;
        if(this.stamp.stamp_data){
          this.stamp.stamp_data.forEach((value) => {
            tmpArray = [];
            for (let i = start; i <= value.complete_number; i++) {
              start++;
              if (value.count < i) {
                tmpArray.push(i);
              }
            }
            this.empty_stamps.push(tmpArray);
          })
        }
      }

      // ポイントランク情報取得結果を設定
      if(this.point.rank != null){ //ランク機能を利用しているかどうか判別
        if (!point_rankup_res) return false;
        this.$set(this, 'point_rankup', point_rankup_res);

        if(this.point_rankup.rank != 1
            && this.point_rankup.rankuped == 1){ //ランクアップしていた場合、ランクアップダイアログを表示
          this.$refs.pointRankUpDialog.open()
        }
      }

      //ポイント履歴取得結果を設定
      if (!point_history_res) return false;
      this.$set(this, 'point_history', point_history_res);

      this.loading = false;
    } catch(e) {
      this.loading = false
      this.callDialog('接続エラー', '一時的なエラーです。ネットワークの状態が不安定です。再度お試しください', 3);
    }
  },
  methods: {
    showPointHistoryDialog() {
      this.$refs.pointHistoryDialog.open();
    },
    showPointAboutDialog() {
      this.$refs.pointAboutDialog.open();
    },
    showStampQRDialog() {
      this.$refs.qrDialog.open();
    },
    showPointQrResultMessageDialog(point_qr_result_message) {
      this.point_qr_result_message = point_qr_result_message;
      this.pointQrResultMessageDialog = true;
    },
    showPointQRReader() {
      this.loading = true
      let tonative_req = {
        'destination': 'pointQrActivity',
        'feature_id': 'point',
        'screen_id': 'point_qr_reader',
        'transition_type': 3,
      };

      if (!(this.screenTransition(tonative_req))) {
        this.loading = false
        this.callDialog('遷移エラー', '遷移情報が不正です。再度アクセスして下さい。', 2);
      }else{
        this.loading = false
      }
    },
    showStampAboutDialog() {
      this.$refs.stampAboutDialog.open();
    },
    closeHelloDialog() {
      if (this.neverShowsHelloPointDialog) {
          this.storageSave(JSON.stringify({
            'never_shows_hello_point_dialog'   : true,
          }));
        }
      this.helloDialog = false
    },
    closePointQrResultMessageDialog() {
      this.pointQrResultMessageDialog = false
      this.point_qr_result_message = null;
    },

    showStampActivity(id, auth_type, shop_id){
      this.loading = true
      let destination = null
      let screenID = null
      switch (auth_type) {
        case 1:
          destination = 'stampAuthCodeActivity'
          screenID = 'stamp_auth_code'
          break;
        case 2:
          destination = 'stampAuthQrActivity'
          screenID = 'stamp_auth_qr'
          break;
        case 3:
          destination = 'stampAuthBeaconActivity'
          screenID = 'stamp_auth_beacon'
          break;
        case 4:
          destination = 'stampAuthWifiActivity'
          screenID = 'stamp_auth_wifi'
          break;
        default:
          destination = 'stampAuthCodeActivity'
          screenID = 'stamp_auth_code'
          break;
      }
      let tonative_req = {
        'destination': destination,
        'feature_id': 'stamp',
        'screen_id': screenID,
        'transition_type': 3,
        'stamp_id': id,
        'shop_id': shop_id,
        //暫定対応
        'top_type': 1,
        'cassette_id': 1
      };

      if (!(this.screenTransition(tonative_req))) {
        this.loading = false
        this.callDialog('遷移エラー', '遷移情報が不正です。再度アクセスして下さい。', 2);
      }else{
        this.loading = false
      }
    },
    startCountdown() {
        this.intervalId = setInterval(() => {
            const nowJP = moment().tz(this.timezone).format('YYYY-MM-DDTHH:mm:ss');
            let now = new Date(nowJP).getTime();
            this.timeLeft = (this.timestamp - now) / 1000;
            if (this.timeLeft <= 0) {
                clearInterval(this.intervalId);
                this.reloadBarcode();
            }
        }, 1000);
    },
    async reloadBarcode() {
      this.openLoad();
      //ワンタイムコード生成API
      await this.apiCall('/point/generate-barcode', {params:{barcode:this.barcode}});
      //ワンタイムコード取得API
      var dataApi = await this.apiCall('/point/get-barcode', {params:{barcode:this.barcode}})
        .catch(()=> {
            this.timeLeft = 0;
            clearInterval(this.intervalId);
            this.isNotErr = false;
            this.closeLoad();
            return;
        });
      //ワンタイムコードをバーコードに反映
      if(dataApi.one_time_code && 
          (
            this.timestamp == null || 
            new Date(dataApi.expiration_date).getTime() > this.timestamp
          )
      ) {
          this.timeLeft = 0;
          clearInterval(this.intervalId);
          this.callBarcode(dataApi);
          this.startCountdown();
      }
      this.isNotErr = true;
      this.closeLoad();
    },
    callBarcode(res) {
        this.barcode = res.one_time_code;
        this.timestamp =  new Date(res.expiration_date).getTime();
        this.timezone = res.time_zone;
        this.local = res.local;
        JsBarcode(this.$refs.barcode, this.barcode, {
            format: 'CODABAR',
            displayValue: true,
            text: this.barcode
        });
    },
    openLoad() {
        this.isLoad = true;
        this.loading = true;
    },
    closeLoad() {
        this.loading = false;
        this.isLoad = false;
    }
  },
  computed: {
    formatTime() {
        var seconds = '00';
        if(this.timeLeft % 60 == 0) {
            seconds = '00';
        } else {
            seconds = this.timeLeft % 60;
        }
        var time = Math.floor(this.timeLeft / 60).toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');
        const [minutes, seconds2] = time.split(":").map(parseFloat);
        const formattedTime = `${minutes}:${seconds2.toFixed(0).padStart(2, "0")}`;
        if(this.timeLeft <= 0) {
            return '0:00';
        }
        return formattedTime;
      },
  },
  async mounted() {
    // created→mountedのはずだが、順番が必ずしも保たれない？冗長だがmountedでもチェックする。こちらはログイン画面遷移要求を出さない。
    try {
      // アクセストークンチェックAPI
      const token_check_req = {}
      await this.apiCallCustomerPF('/access_token/check/' + this.init.device_uid, token_check_req)
      this.authorized = true;
    } catch(e) {
      this.authorized = false
      console.log(e)
      return
    }
    this.reloadBarcode();
    this.interval = setInterval(() => {

      // https://docs.google.com/spreadsheets/d/17f-xMyU4HD0ZYKRh8f7LI8grWppSQpxLV9Z6r4eFBNc/edit?gid=1495422315#gid=1495422315&range=C7
      this.value = (!this.point.rankmax_flg) ? ((this.point.grant_point - this.point.rank_remaining + 1) / this.point.grant_point) * 100 : 100;
      // this.value = (this.point.point / this.point.grant_point) * 100;
      this.num = this.point.point;
    }, 1500);

    // if (!this.init.never_shows_hello_point_dialog) {
    //   this.helloDialog = true
    // }
  },
};
</script>

<style scoped>
.bigpoint {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  line-height: 1.25;
}
.bigpoint2 {
  font-size: 26px;
  font-family: "Oswald", sans-serif !important;
  line-height: 1;
}
.bigpoint3 {
  font-size: 1.125rem;
  font-weight: bold;
  line-height: 1.25;
}
.smallpoint {
  font-size: 1rem;
  font-weight: bold;
  color: #333333;
}
.xsmallpoint {
  font-size: 12px;
  font-weight: bold;
  color: #333;
}
.maincolor-border-btn {
    border: solid 1px var(--main_color);
    color: var(--main_color);
    background-color: #fff !important;
    height: 1.5rem !important;
    width:100%;
}
.maincolor-border-btn-disabled {
    height: 1.5rem !important;
}
.v_line_left {
  border-left: groove ;
  padding-left: 3px;
  margin : 5px
}
.v-application {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}
.header {
  height: 3rem;
  background-color: #fff;
  box-shadow: 0 3px 6px rgb(0 0 0 / 15%);
}
p {
  margin-bottom: 0;
}
.bg_stamp {
  display: block;
  padding: 0.5rem;
  margin: -0.5rem 0rem 0.5rem 0rem;
  background: url("~@/assets/point/bg_stamp.jpg") top 39% center /cover no-repeat;
  color: #fff;
  border-radius: 10px 10px 0 0;
  font-size: 1rem;
}

.barcode 
{
    margin-top: 20px;
}
.barcode .div-btn
{
    display: flex; align-items: center; justify-content: flex-end; line-height: 1; margin-top: 15px;
}
.barcode svg
{
    max-width: 100%;
    height: unset !important;
}
.barcode .button-re
{
    margin-left: 10px;
}
.active-load img {
    animation: spin 2s linear infinite;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
    
/*
  v-progress-circularの背景色は以下の値を/src/sass/variables.scss内で定義
  $progress-circular-underlay-stroke
*/
</style>
